import {get,post,del,put} from '../http/http'
import { storebaseUrl} from "../http/baseUrl";
import baseUrl from '../http/baseUrl'
//店铺列表
export const getStoreList=(params)=> {
  return get(`${storebaseUrl}/v1/elshop/all`,params, {hideLoading: true});
}


export const getThirdPatentIndex = (params) => {
  return get(`${baseUrl}/v1/shops/all`,params, { hideLoading: true })
}
<template>
  <div>
    <div class="select-body">
      <div class="sb-type">
        <span>店铺类型：</span>
        <span v-for="(item,index) in storeType" :key="index" @click="selectStoreType(index)"
              :class="['sb-type-text','display-flex-center',storeTypeChoice === index? 'sb-type-text-select' : '',]">{{
            item.name
          }}</span>
      </div>
      <div class="sb-sort">
        <div class="dp-flex"><span>排</span><span style="width: 30px"></span><span>序：</span></div>
        <span @click="chooseMoRen()"
              style="margin-left: 20px;cursor:pointer;"
              :class="{'active' : shopparams.sortField===''}">默认</span>
        <span @click="chooseGOODS()"
              style="margin-left: 25px;cursor:pointer;position: relative"
              :class="[{'active' : shopparams.sortField==='GOODS'},{'activeASC' : shopparams.sortField=='GOODS' && shopparams.sortOrder=='ASC'}]">商品数
          <span style="position: absolute;top: -5px"><Icon type="md-arrow-dropup"/></span>
          <span style="position: absolute;top: 4px"><Icon type="md-arrow-dropdown"/></span>
        </span>
        <span @click="chooseATTENTION()"
              style="margin-left: 25px;cursor:pointer;position: relative"
              :class="[{'active' : shopparams.sortField==='ATTENTION'},{'activeASC' : shopparams.sortField=='ATTENTION' && shopparams.sortOrder=='ASC'}]">关注度
          <span style="position: absolute;top: -5px"><Icon type="md-arrow-dropup"/></span>
          <span style="position: absolute;top: 4px"><Icon type="md-arrow-dropdown"/></span>
        </span>
      </div>
    </div>

    <!--    <div class="center"-->
    <!--         style="margin-top: 10px;display: flex;justify-content:flex-start;flex-wrap: wrap;background-color: rgb(250,250,250)"-->
    <!--         id="service_list">-->
    <!--      <div v-for="(item,index) in storeList" :key="index"-->
    <!--           style="height: 370px;width: 230px;;background-color: white;padding: 0 10px;margin: 0 5px;margin-bottom: 20px"-->
    <!--           class="per-detail-hover" @click="gotostore(item.id,item.shopTypeId)">-->
    <!--        <div style="height: 120px;padding: 20px 0px">-->
    <!--          &lt;!&ndash;          专家&ndash;&gt;-->
    <!--          <div v-if="item.shopTypeId==3" style="float: left">-->
    <!--            <Avatar :src="item.imageUrl" icon="ios-person" size="70" style="margin: 0 20px 0 auto;"/>-->
    <!--          </div>-->
    <!--          &lt;!&ndash;          高校&ndash;&gt;-->
    <!--          <div v-else-if="item.shopTypeId==2 || item.shopTypeId == 4" style="float: left">-->
    <!--            <Avatar v-if="item.imageUrl" :src="item.imageUrl" icon="ios-person"-->
    <!--                    style="margin: -10px auto 0;width: 100px;height: 90px"/>-->
    <!--            <Avatar v-else size="70" style="margin: 0 auto;margin-right:20px">U</Avatar>-->
    <!--          </div>-->
    <!--          <div v-else style="float: left">-->
    <!--            <Avatar :src="item.imageUrl" icon="ios-person" size="70" style="margin: 0 20px 0 auto;"/>-->
    <!--          </div>-->
    <!--          <div-->
    <!--              style="float: left;margin-left: 5px;width: 100px;display: flex;flex-flow: column;justify-content: center;height: 70px;">-->
    <!--            <div><span style="font-size: 18px">{{ item.realName }}</span></div>-->
    <!--            <div style="font-size: 14px">-->
    <!--              <span>{{ item.pa }}</span>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div v-if="thirdFlag == false"-->
    <!--             style="height: 90px;background-color: #F7F8FC;padding: 20px 10px;margin-bottom: 20px">-->
    <!--          <div style="font-size: 14px;margin-bottom: 10px;height: 20px;">-->
    <!--            <div style="float: left"><span style="color: #666666">{{ item.shopTypeId == 3 ? '专家' : '高校' }}专利数</span>-->
    <!--            </div>-->
    <!--            <div style="float: right"><span style="color: #666666">{{ item.patentCount }}件</span></div>-->
    <!--          </div>-->
    <!--          <div style="font-size: 14px;height: 20px;">-->
    <!--            <div style="float: left"><span style="color: #666666">{{ item.shopTypeId == 3 ? '专家' : '高校' }}商品数</span>-->
    <!--            </div>-->
    <!--            <div style="float: right"><span style="color: #666666">{{ item.goodsCount }}件</span></div>-->
    <!--          </div>-->

    <!--        </div>-->
    <!--        <div v-if="thirdFlag == true"-->
    <!--             style="height: 90px;background-color: #F7F8FC;line-height: 90px;margin-bottom: 20px">-->
    <!--          <div style="font-size: 14px;height: 20px;">-->
    <!--            <div style="float: left"><span style="color: #666666">{{ item.shopTypeId == 3 ? '专家' : '' }}商品数</span></div>-->
    <!--            <div style="float: right"><span style="color: #666666">{{ item.goodsCount || 0 }}件</span></div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div style="height: 45px;font-size: 14px">-->
    <!--              <span v-if="item.direction" style="line-height: 25px" class="ellipse-2">-->
    <!--                专业领域：{{ item.direction }}-->
    <!--              </span>-->
    <!--        </div>-->
    <!--        <div class="shopbtn" @click="gotostore(item.id,item.shopTypeId)">进入店铺</div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="list-body">
      <div class="lb-body" v-for="(item,index) in storeList" :key="index" @click.stop="gotostore(item.id,item.shopTypeId)">
        <img class="lbb-icon" :src="item.imageUrl"/>
        <div class="lbb-des">
          <div class="lbb-des-title">
            <div class="lbb-des-title-text ellipse-1">{{ item.name }}</div>
            <div class="lbb-des-title-tag display-flex-center">已认证</div>
          </div>
          <div class="lbb-des-tel">电话：{{item.contactPhone?item.contactPhone:'暂无'}}</div>
          <div class="lbb-des-tel">地址：暂无</div>
          <div class="lbb-rate">
            <div>
              <span style="margin-right: 5px">服务质量</span>
              <Rate disabled :value="item.serviceQuality||5" />
            </div>
            <div style="margin-left: 20px">
              <span style="margin-right: 5px">服务态度</span>
              <Rate disabled :value="item.serviceAttitude||5" />
            </div>
            <div style="margin-left: 20px">
              <span style="margin-right: 5px">响应速度</span>
              <Rate disabled :value="item.serviceSpeed||5" />
            </div>
          </div>
        </div>
        <div class="lbb-num">商品数：<span style="color: rgba(254, 121, 48, 1)">{{item.goodsCount||0}}</span>件</div>
        <div class="lbb-button">
          <div class="lbb-buttonA display-flex-center" @click.stop="openZoosUrl">立即咨询</div>
          <div class="lbb-buttonB display-flex-center">进入店铺</div>
        </div>
      </div>
      <div style="display: flex;flex-direction: row-reverse;padding: 40px 20px">
        <Page :total="totalNum" :current="shopparams.pageNum" :page-size="shopparams.pageSize" show-elevator @on-change="changePage"/>
      </div>

    </div>

  </div>
</template>

<script>
import {
  // getStoreList,
  getThirdPatentIndex
} from "@/plugins/api/storeList";

export default {
  name: "storeList",
  data() {
    return {
      storeType: [
        {type: 1, name: '第三方服务店铺'},
        {type: 2, name: '高校技术转移中心'},
        // {type: 2, name: '高校店铺'},
        // {type:7,name:'第三方店铺'}
      ],
      thirdFlag: false,
      storeTypeChoice: 1,
      shopparams: {
        shopTypeId: '',
        sortField: '',//排序字段(ATTENTION:关注度；PATENT:专利数；GOODS:商品数)
        sortOrder: '',//排序方式(DESC降序、ASC升序)
        pageSize: 6,
        pageNum: 1,
        type: 2,//店铺类型 1.服务店铺2.科技成果中心
      },
      totalNum: 0,
      storeList: [],
      serviceQuality:5,
      serviceAttitude:5,
      serviceSpeed:5
    }
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to, from) {
        if (this.$route.query.type == 1) {
          this.shopparams.type = 1;
          this.storeTypeChoice = 0;
        }
        this.getstoreList()
      },
    }
  },
  methods: {
    chooseMoRen() {
      this.shopparams.sortField = "";
      this.shopparams.sortOrder = "";
      this.getstoreList();
    },
    //店铺列表
    getstoreList() {
      this.shopparams.shopTypeId = 8
      this.thirdFlag = true;
      getThirdPatentIndex(
          this.shopparams
      ).then((res) => {
        if (res.code === 0) {
          this.storeList = res.result.list
          this.totalNum = res.result.total
        }
      })


    },
    //进入店铺
    gotostore(shopId, shopTypeId) {
      let path
      if (shopTypeId == 3) {
        path = 'experts'
      } else if (shopTypeId == 2 || shopTypeId == 4) {
        path = 'colleges'
      } else {
        path = 'serviceOutStoreList'
      }
      this.$router.push({
        path: path,
        query: {
          shopId
        }
      })
    },
    //切换tab
    selectStoreType(index = 0) {
      if (index === 7) {
        index = 3;
      }
      this.shopparams.type = this.storeType[index].type
      this.storeTypeChoice = index
      this.shopparams.pageNum = 1
      this.getstoreList()
    },
    //排序
    chooseGOODS() {
      if (this.shopparams.sortField == 'GOODS') {
        this.shopparams.sortOrder = this.shopparams.sortOrder == 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.shopparams.sortField = 'GOODS';
        this.shopparams.sortOrder = 'DESC'
      }

      this.getstoreList();
    },
    // choosePATENT() {
    //   if (this.shopparams.sortField == 'PATENT') {
    //     this.shopparams.sortOrder = this.shopparams.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    //   } else {
    //     this.shopparams.sortField = 'PATENT';
    //     this.shopparams.sortOrder = 'DESC'
    //   }
    //   this.getstoreList();
    // },
    chooseATTENTION() {
      if (this.shopparams.sortField == 'ATTENTION') {
        this.shopparams.sortOrder = this.shopparams.sortOrder == 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.shopparams.sortField = 'ATTENTION';
        this.shopparams.sortOrder = 'DESC'
      }
      this.getstoreList();
    },
    // 翻页
    changePage(index) {
      this.shopparams.pageNum = index;
      this.getstoreList();
    },
    //在线咨询
    openZoosUrl() {
      openZoosUrl('chatwin');
    },
  }
}
</script>

<style scoped lang="scss">
.center {
  width: 1200px;
  margin: 0 auto;
}

.active {
  color: #1890FF;
}

.active span:last-child {
  color: #333;
}

.activeASC span:first-child {
  color: #333;
}

.activeASC span:last-child {
  color: #1890FF;
}

.shopbtn {
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 140px;
  cursor: pointer;
  color: #1890FF;
  border: 1px solid #1890FF;
  margin: 0 auto;
  margin-top: 40px
}

.shopbtn:hover {
  background: #ff6a00;
  border: 1px solid #ff6a00;
  color: #fff;
}

.select-body {
  width: 1200px;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin: 30px auto 20px auto;
  padding-left: 20px;
}

.sb-type {
  height: 76px;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  display: flex;
  align-items: center;

  font-size: 16px;
  font-weight: 400;
  color: #222222;
}

.sb-type-text {
  width: 140px;
  height: 36px;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  margin-left: 17px;
  cursor: pointer;
}

.sb-type-text-select {
  background-color: rgba(15, 98, 223, 1);
  color: #FFFFFF;
}

.sb-sort {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #222222;
}

.list-body {
  width: 1200px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
  margin: 20px auto 50px auto;

}

.lb-body {
  height: 180px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding: 29px 37px 29px 20px;
  cursor: pointer;
}

.lb-body:hover {
  box-shadow: 1px 1px 10px rgba(15, 98, 223, 1);
}

.lbb-icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.lbb-des {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 28px;
  overflow: hidden;
  width: 643px;
}

.lbb-des-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lbb-des-title-text {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}

.lbb-des-title-tag {
  width: 60px;
  height: 22px;
  background: #D7F1D9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #17C22D;
  flex-shrink: 0;
  margin-left: 10px;
}

.lbb-des-tel{
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.lbb-rate{
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.lbb-num{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 70px;
  flex-shrink: 0;
}

.lbb-button{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: flex-end;
  margin-left: 50px;
}

.lbb-buttonA{
  width: 120px;
  height: 34px;
  background: #DCEAFE;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  cursor: pointer;
}

.lbb-buttonB{
  width: 120px;
  height: 34px;
  background: #FE7930;
  border: 1px solid #FE7930;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}
</style>
